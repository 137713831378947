import { OpenAIChatMessage, OpenAIConfig } from "./OpenAI.types";

export const defaultConfig = {
  model: "julep-ai/samantha-1-turbo",
  temperature: 0.7,
  max_tokens: 200,
  top_p: 1,
  frequency_penalty: 0,
  presence_penalty: 0.0,
  repetition_penalty: 1.0,
};

export type OpenAIRequest = {
  messages: OpenAIChatMessage[];
} & OpenAIConfig;

export interface ChatCompletion {
  data: {
    id: string;
    object: string;
    created: number;
    model: string;
    choices: Choice[];
    usage: {
      prompt_tokens: number;
      total_tokens: number;
      completion_tokens: number;
    };
  };
}

interface Choice {
  index: number;
  message: {
    role: string;
    content: string;
    name: null;
  };
  finish_reason: string;
}

export const getOpenAICompletion = async (token: string, payload: OpenAIRequest): Promise<Response> => {
  try {
    const response = await fetch("https://api-alpha.julep.ai/v1/chat/completions", {
      headers: {
        Authorization: `Bearer ${token}`,
        "Content-Type": "application/json",
      },
      method: "POST",
      body: JSON.stringify(payload),
    });

    const data = await response.json();
    console.log("response in api", { data });

    // Check for errors
    // if (!response.ok) {
    //   throw new Error(await response.text());
    // }

    return response;
  } catch (error) {
    throw error;
  }
};
