import jwt from "jsonwebtoken";

const env = import.meta.env.VITE_ENV;
const secret = env === "production" ? import.meta.env.VITE_JWT_SECRET_PROD : import.meta.env.VITE_JWT_SECRET_DEV;

const formattedSecret = String.raw`${secret}`;

export function generateJWT(userId: string, email: string, forBackend: boolean = false) {
  "use server";

  const payload = {
    sub: userId,
    email,
    iat: Math.floor(Date.now() / 1000),
    expiresIn: forBackend ? "1y" : "24h",
    rateLimitPerMinute: forBackend ? 3500 : 500,
    quotaReset: "1h",
    clientEnvironment: forBackend ? "server" : "browser",
    serverEnvironment: env === "production" ? "production" : "development",
    version: "v0.2",
  };

  const token = jwt.sign(payload, formattedSecret, {
    expiresIn: forBackend ? "1y" : "23h",
    algorithm: "HS512",
  });

  return token;
}

export function verifyJWT_(token: string) {
  "use server";
  try {
    const decoded = jwt.verify(token, formattedSecret, {});
    return { valid: true, decoded };
  } catch (error) {
    return { valid: false, error };
  }
}
