import { ParentProps, createContext, onMount, useContext } from "solid-js";
import mixpanel from "mixpanel-browser";

export const useMixpanelProvider = () => {
  onMount(() => {
    mixpanel.init(import.meta.env.VITE_MIXPANEL_PROJECT_KEY, {track_pageview: true});
    mixpanel.set_config({persistence: "localStorage"});
  });

  return { mixpanel } as const;
};

type MixpanelContextType = ReturnType<typeof useMixpanelProvider>;

export const MixpanelContext = createContext<MixpanelContextType>();

export const MixpanelProvider = (props: ParentProps) => {
  const value = useMixpanelProvider();

  return <MixpanelContext.Provider value={value}>{props.children}</MixpanelContext.Provider>;
};

export const useMixpanel = () => useContext(MixpanelContext)!;
