import { ParentProps, createContext, createEffect, createSignal, onMount, useContext } from "solid-js";
import { cookieStorage } from "@solid-primitives/storage";
import { createAsync, useAction } from "@solidjs/router";
import { createStorage } from "unstorage";
import localStorageDriver from "unstorage/drivers/localstorage";

import { apiKeyCache, getJWT, jwtCache, regenerateJWTAction, verifyJWT } from "~/api";
import { signOut as signOut_ } from "~/api";
import { createClient } from "~/utils/supabase/client";
import { useMixpanel } from "~/context/MixpanelProvider";
import { isServer } from "solid-js/web";

export const useAuthProvider = () => {
  const jwt = createAsync(() => jwtCache());
  const apiKey = createAsync(() => apiKeyCache());
  const verifyToken = useAction(verifyJWT);
  const regenrateJWT = useAction(regenerateJWTAction);
  const signOut = useAction(signOut_);
  const supabase = createClient();
  const { mixpanel } = useMixpanel();

  createEffect(() => {
    if (isServer) return;
    if (!jwt()) {
      signOut();
      return;
    }
    verifyToken(jwt()).then(async (res) => {
      if (!res.valid) {
        signOut();
        return;
      }

      const {
        data: { user },
      } = await supabase.auth.getUser();
      mixpanel.identify(user?.id);
      if (user?.user_metadata?.full_name) {
        mixpanel.people.set({
          $email: user?.email,
          $name: user?.user_metadata?.full_name,
        });
      } else {
        const { data, error } = await supabase.from("users").select("name, email_id");
        if (data && data[0]) {
          mixpanel.people.set({ $email: user?.email, $name: data[0].name });
        }
      }
      mixpanel.track("Login");
    });
  });

  //   onMount(() => {
  //     if (!user()) return;
  //     if (typeof window !== "undefined") {
  //       const storage = createStorage({
  //         driver: localStorageDriver({ base: "app" }),
  //       });
  //       storage.getItem("api-key").then((apiKey) => {
  //         if (apiKey) {
  //           return setApiKey(apiKey as string);
  //         }
  //         getTykKey(user().id, user().email)
  //           .then((res: any) => {
  //             setApiKey(res.key_id);
  //             storage.setItem("api-key", res.key_id);
  //           })
  //           .catch((err: Error) => {
  //             // toast().error({
  //             //   title: "Failed to get api key",
  //             //   description: err.message,
  //             // });
  //             console.error(err);
  //           });
  //       });
  //     }
  //   });

  return [{ jwt, apiKey }] as const;
};

type AuthContextType = ReturnType<typeof useAuthProvider>;

export const AuthContext = createContext<AuthContextType>();

export const AuthProvider = (props: ParentProps) => {
  const value = useAuthProvider();

  return <AuthContext.Provider value={value}>{props.children}</AuthContext.Provider>;
};

export const useAuth = () => useContext(AuthContext)!;
