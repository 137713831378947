import { v4 as uuid } from "uuid";
import qs from "query-string";
import { isServer } from "solid-js/web";
import * as Sentry from "@sentry/browser";

import {
  clearHistory,
  Conversation,
  getHistory,
  storeConversation,
  History,
  deleteConversationFromHistory,
  updateConversation,
} from "~/utils/History";
import {
  defaultConfig,
  OpenAIChatMessage,
  OpenAIConfig,
  OpenAISystemMessage,
  OpenAIChatModels,
  JulepAIChatMessageRole,
  OpenAIRequest,
} from "~/utils/OpenAI";

import JSONCrush from "jsoncrush";
import { isValidJSONString } from "~/utils/utils";
import { ParentProps, createContext, createEffect, createMemo, createSignal, useContext, DEV } from "solid-js";
import { action, createAsync, useAction, useLocation, useSubmission } from "@solidjs/router";
import { createToaster } from "@ark-ui/solid";
import * as Toast from "../components/ui/toast";
import { createStorage } from "unstorage";
import localStorageDriver from "unstorage/drivers/localstorage";
import { getSessionUser, verifyJWT } from "~/api";
import {
  chatAction,
  createAgentAction,
  createSessionAction,
  createUserAction,
  getAgentAction,
  getSessionAction,
  getSessionHistoryAction,
  getSessionHistoryCache,
  getUserAction,
  getUserCache,
  listSessionsCache,
  updateAgentAction,
  updateSessionAction,
  updateUserAction,
} from "~/api/julep";
import { ChatMLMessage } from "@julep/sdk/dist/cjs/api";
import { useAuth } from "./AuthProvider";
import { verifyJWT_ } from "~/api/jwt";

type UserName = {
  id: string;
  name: string;
};

const defaultContext = {
  systemMessage: {
    role: "system",
    name: "situation",
    content: "You are a helpful AI chatbot.",
  } as OpenAISystemMessage,
  assistantName: "Julia",
  isContinue: false,
  systemNames: [
    {
      id: "information",
      name: "information",
    },
    {
      id: "thought",
      name: "thought",
    },
  ],
  userNames: [
    {
      id: uuid(),
      name: "David",
    },
  ],
  updateUsernames: (names: UserName[]) => {},
  messages: [] as OpenAIChatMessage[],
  config: defaultConfig as OpenAIConfig,
  updateSystemMessage: (content: string) => {},
  addMessage: () => {},
  removeMessage: (id: string) => {},
  conversationName: "",
  conversationId: "",
  deleteConversation: () => {},
  updateConversationName: () => {},
  conversations: {} as History,
  clearConversations: () => {},
  clearConversation: () => {},
  loadConversation: (id: string, conversation: Conversation) => {},
  updateMessageRole: (id: string, role: JulepAIChatMessageRole) => {},
  updateMessageName: (id: string, nameId: string) => {},
  updateMessageContent: (id: string, content: string) => {},
  updateConfig: (newConfig: Partial<OpenAIConfig>) => {},
  submit: () => {},
  loading: false,
  error: "",
  updateAssistantName: (name: string) => {},
  deleteUserName: (id: string) => {},
  compressJson: () => "",
  setUsernames: (names: UserName[]) => {},
};

const useJulepProvider = () => {
  const location = useLocation();

  const [loading, setLoading] = createSignal(defaultContext.loading);
  const [error, setError] = createSignal(defaultContext.error);
  // Conversation state
  const [conversations, setConversations] = createSignal<History>(defaultContext.conversations as History);
  const [conversationId, setConversationId] = createSignal<string>(defaultContext.conversationId);
  const [conversationName, setConversationName] = createSignal(defaultContext.conversationName);
  const [systemMessage, setSystemMessage] = createSignal<OpenAISystemMessage>(defaultContext.systemMessage);
  const [config, setConfig] = createSignal<OpenAIConfig>(defaultConfig);
  const [messages, setMessages] = createSignal<OpenAIChatMessage[]>(defaultContext.messages);
  const [assistantName, setAssistantName] = createSignal<string>(defaultContext.assistantName);
  const [userNames, setUsernames] = createSignal<UserName[]>(defaultContext.userNames);
  const [isContinue, setIsContinue] = createSignal(defaultContext.isContinue);
  const [userId, setUserId] = createSignal<string | undefined>();
  const [agentId, setAgentId] = createSignal<string | undefined>();
  const [sessionId, setSessionId] = createSignal<string | undefined>();
  const [updatedAssistant, setUpdatedAssistant] = createSignal(false);
  const [updatedUser, setUpdatedUser] = createSignal(false);
  const [updatedSituation, setUpdatedSituation] = createSignal(false);

  const getUser = useAction(getUserAction);
  const getAgent = useAction(getAgentAction);
  const getSession = useAction(getSessionAction);
  const createUser = useAction(createUserAction);
  const updateUser = useAction(updateUserAction);
  const updateAgent = useAction(updateAgentAction);
  const createAgent = useAction(createAgentAction);
  const createNewSession = useAction(createSessionAction);
  const updateSession = useAction(updateSessionAction);
  const chat = useAction(chatAction);
  const getSessionHistory = useAction(getSessionHistoryAction);
  const getSessionHistoryStatus = useSubmission(getSessionHistoryAction);
  const [{ jwt, apiKey }] = useAuth();
  const verifyToken = useAction(verifyJWT);

  const initialized = createMemo(() => !getSessionHistoryStatus.pending);

  const sessions = createAsync(async () => {
    if (!jwt()) return;
    const isValid = await verifyJWT_(jwt());
    if (!isValid) return [];

    try {
      const sessions = await listSessionsCache(jwt()!);
      return sessions;
    } catch (error: any) {
      if (!DEV) {
        Sentry.captureMessage(`Sessions Error: ${error.message}, JWT: ${jwt()}`, "error");
      }
      return [];
    }
  });

  const validateToken = async () => {
    if (!jwt()) return false;
    const res = await verifyToken(jwt());
    return res.valid;
  };

  const fetchHistory = async () => {
    try {
      const history = await getSessionHistory(jwt()!, sessionId()!);

      const formattedHistory = history.map((message: ChatMLMessage) => {
        return {
          id: message.id,
          role: message.role,
          content: message.content,
          name: message.name,
          forSubmission: false,
        };
      }) as OpenAIChatMessage[];

      setMessages(formattedHistory);
    } catch (error: any) {
      if (!DEV) {
        Sentry.captureMessage(`Session History Error: ${error.message}, JWT: ${jwt()}`, "error");
      }
      toast().error({
        title: error.message,
      });

      setMessages([]);
    }
  };

  const setup = async () => {
    const tokenValid = await validateToken();

    if (!tokenValid || !sessionId()) return;
    await fetchHistory();
  };

  createEffect(async () => {
    if (isServer) return;
    setup();
  });

  const selectSession = async (id: string) => {
    setSessionId(id);

    const session = sessions()?.find((session) => session.id === id);

    setUserId(session?.user_id);
    setAgentId(session?.agent_id);
    setSystemMessage({
      content: session?.situation!,
      role: "system",
      name: "situation",
    });

    try {
      const user = await getUser(jwt()!, session?.user_id!);
      setUsernames([
        {
          id: user.id,
          name: user.name!,
        },
      ]);
    } catch (error: any) {
      toast().error({
        title: error.message,
      });
      if (!DEV) {
        Sentry.captureMessage(`User Fetched Error: ${error.message}, JWT: ${jwt()}`, "error");
      }
      return;
    }

    try {
      const agent = await getAgent(jwt()!, session?.agent_id!);
      setAssistantName(agent.name);
    } catch (error: any) {
      toast().error({
        title: error.message,
      });
      if (!DEV) {
        Sentry.captureMessage(`Agent Fetched Error: ${error.message}, JWT: ${jwt()}`, "error");
      }
      return;
    }

    fetchHistory();
  };

  const [Toaster, toast] = createToaster({
    placement: "top",
    duration: 1000,
    render(toast) {
      return (
        <Toast.Root>
          <Toast.Title>{toast().title}</Toast.Title>
          <Toast.Description>{toast().description}</Toast.Description>
        </Toast.Root>
      );
    },
  });

  // Load conversation from local storage
  createEffect(() => {
    setConversations(getHistory());
  });

  //   createEffect(() => {
  //     if (isServer) return;
  //     if (!userId()) return;
  //     console.log("storing user id", userId()!);
  //     const storage = createStorage({
  //       driver: localStorageDriver({ base: "app" }),
  //     });
  //     storage.setItem("user-id", userId()!);
  //   });

  //   createEffect(() => {
  //     if (isServer) return;
  //     if (!agentId()) return;
  //     console.log("storing agent id", agentId()!);
  //     const storage = createStorage({
  //       driver: localStorageDriver({ base: "app" }),
  //     });
  //     storage.setItem("agent-id", agentId()!);
  //   });

  //   createEffect(() => {
  //     if (isServer) return;
  //     if (!sessionId()) return;
  //     console.log("storing session id", sessionId()!);
  //     const storage = createStorage({
  //       driver: localStorageDriver({ base: "app" }),
  //     });
  //     storage.setItem("session-id", sessionId()!);
  //   });

  const updateSystemMessage = (content: string) => {
    setSystemMessage({
      role: "system",
      name: "situation",
      content,
    });
    setUpdatedSituation(true);
  };

  const updateAssistantName = (name: string) => {
    setAssistantName(name);
    setMessages((prev) => {
      return prev.map((message) => {
        if (message.role === "assistant") {
          return {
            ...message,
            name,
          };
        }
        return message;
      });
    });
    setUpdatedAssistant(true);
  };

  const updateUsernames = (names: UserName[]) => {
    const userNames_ = userNames();
    setUsernames(names);
    setMessages((prev) => {
      return prev.map((message) => {
        const name = names.find((name) => name.id === message.selectedNameId);
        if (message.role === "user") {
          return {
            ...message,
            name: name?.name || userNames_[0].name,
          };
        }
        return message;
      });
    });
    setUpdatedUser(true);
  };

  const removeMessage = (id: string) => {
    setMessages((prev) => {
      return [...prev.filter((message) => message.id !== id)];
    });
  };

  const deleteUserName = (id: string) => {
    const userNames_ = userNames();
    setUsernames((prev) => {
      const names = prev.filter((name) => name.id !== id);
      return names;
    });
    setMessages((prev) => {
      return prev.map((message) => {
        if (message.role === "user" && message.selectedNameId === id) {
          return {
            ...message,
            selectedNameId: userNames_[0].id,
            name: userNames_[0].name,
          };
        }
        return message;
      });
    });
  };

  const clearConversation = () => {
    setMessages([]);
    setSystemMessage(defaultContext.systemMessage);
    setConversationId("");
  };

  const updateMessageRole = (id: string, role: JulepAIChatMessageRole) => {
    const userNames_ = userNames();
    setMessages((prev) => {
      const index = prev.findIndex((message) => message.id === id);
      if (index === -1) return prev;
      const message = prev[index];
      return [
        ...prev.slice(0, index),
        {
          ...message,
          role,
          name: role === "user" ? userNames_[0].name : role === "system" ? "information" : assistantName(),
          selectedNameId: role === "user" ? userNames_[0].id : message.selectedNameId,
          forSubmission: true,
        },
        ...prev.slice(index + 1),
      ];
    });
  };

  const updateMessageName = (id: string, nameId: string) => {
    const userNames_ = userNames();
    setMessages((prev) => {
      const index = prev.findIndex((message) => message.id === id);
      if (index === -1) return prev;

      const updatedMessages = prev.map((message) => {
        if (message.id === id) {
          let name;
          if (message.role === "user") {
            const foundName = userNames_.find((name) => name.id === nameId);
            name = foundName ? foundName.name : userNames_[0].name;
          } else if (message.role === "system") {
            const foundName = defaultContext.systemNames.find((name) => name.id === nameId);
            name = foundName ? foundName.name : defaultContext.systemNames[0].name;
          } else {
            name = assistantName();
          }
          const updatedMessage = {
            ...message,
            name,
            selectedNameId: nameId,
          };

          return updatedMessage;
        }
        return message;
      });

      return updatedMessages;
    });
  };
  const updateConfig = (newConfig: Partial<OpenAIConfig>) => {
    setConfig((prev) => {
      // If model changes set max tokens to half of the model's max tokens
      if (newConfig.model && newConfig.model !== prev.model) {
        newConfig.max_tokens = Math.floor(OpenAIChatModels[newConfig.model].maxLimit / 2);
      }

      return {
        ...prev,
        ...newConfig,
      };
    });
  };

  const updateMessageContent = (id: string, content: string) => {
    setMessages((prev) => {
      const index = prev.findIndex((message) => message.id === id);
      if (index === -1) return prev;
      const message = prev[index];
      return [
        ...prev.slice(0, index),
        {
          ...message,
          content,
          forSubmission: true,
        },
        ...prev.slice(index + 1),
      ];
    });
  };

  const handleStoreConversation = () => {
    if (!messages() || messages().length === 0) return;

    const conversation = {
      name: conversationName(),
      systemMessage: systemMessage(),
      messages: messages(),
      config: config(),
      lastMessage: Date.now(),
    } as Conversation;

    let id = storeConversation(conversationId(), conversation);
    setConversationId(id);
    setConversations((prev) => ({ ...prev, [id]: conversation }));
  };

  createEffect(() => {
    handleStoreConversation();
  });

  createEffect(() => {
    if (!location.search) return;
    const parsed = qs.parse(location.search);
    const dataStr = parsed.data as string;

    if (!dataStr) return;
    const uncrushed = JSONCrush.uncrush(dataStr);

    if (isValidJSONString(uncrushed)) {
      const data = JSON.parse(uncrushed);

      if (data.userNames) {
        setUsernames(data.userNames);
      }

      if (data.messages) {
        setMessages(data.messages);
      }

      if (data.config) {
        setConfig(data.config);
      }

      if (data.systemMessage) {
        updateSystemMessage(data.systemMessage.content);
      }

      if (data.assistantName) {
        setAssistantName(data.assistantName);
      }
    }
  });

  const loadConversation = (id: string, conversation: Conversation) => {
    setConversationId(id);

    const { systemMessage, messages, config, name } = conversation;

    setSystemMessage(systemMessage);
    setMessages(messages);
    updateConfig(config);
    setConversationName(name);
  };

  const clearConversations = () => {
    clearHistory();

    setMessages([]);
    setConversationId("");
    setConversations({});
  };

  const deleteConversation = (id: string) => {
    deleteConversationFromHistory(id);
    setConversations((prev) => {
      const { [id]: _, ...rest } = prev;
      return rest;
    });

    if (id === conversationId()) clearConversation();
  };

  const updateConversationName = (id: string, name: string) => {
    setConversations((prev) => {
      const conversation = prev[id];
      if (!conversation) return prev;
      return {
        ...prev,
        [id]: {
          ...conversation,
          name,
        },
      };
    });

    if (id === conversationId()) setConversationName(name);

    updateConversation(id, { name });
  };

  const submitAction = action(async () => {
    if (loading()) return;
    setLoading(true);
    let userId_: string, agentId_: string, sessionId_: string;

    if (!userId()) {
      try {
        const userResponse = await createUser(jwt(), {
          name: userNames()[0].name,
        });

        setUserId(userResponse.id);
        userId_ = userResponse.id;
      } catch (error: any) {
        if (!DEV) {
          Sentry.captureMessage(`User Create Error: ${error.message}, JWT: ${jwt()}`, "error");
        }
        setLoading(false);
        return;
      }
    } else {
      userId_ = userId()!;
    }

    if (!agentId()) {
      try {
        const agentResponse = await createAgent(jwt(), { name: assistantName(), about: "", instructions: [] });

        if (!DEV) {
          Sentry.captureMessage(`Agent Created: ${agentResponse.id}, JWT: ${jwt()}`);
        }

        agentId_ = agentResponse.id;
        setAgentId(agentResponse.id);
      } catch (error: any) {
        toast().error({
          title: "Error",
          description: error.message,
        });
        if (!DEV) {
          Sentry.captureMessage(`Agent Create Error: ${error.message}, JWT: ${jwt()}`, "error");
        }
        setLoading(false);
        return;
      }
    } else {
      agentId_ = agentId()!;
    }

    if (!sessionId()) {
      try {
        const sessionResponse = await createNewSession(jwt()!, {
          userId: userId_!,
          agentId: agentId_!,
          situation: systemMessage().content,
        });

        sessionId_ = sessionResponse?.id!;
        setSessionId(sessionResponse?.id);
      } catch (error: any) {
        toast().error({
          title: "Error",
          description: error.message,
        });
        if (!DEV) {
          Sentry.captureMessage(`Session Create Error: ${error.message}, JWT: ${jwt()}`, "error");
        }
        setLoading(false);
        return;
      }
    } else {
      sessionId_ = sessionId()!;
    }

    if (updatedUser()) {
      try {
        await updateUser(jwt()!, {
          userId: userId()!,
          data: { name: userNames()[0].name },
        });
        setUpdatedUser(false);
      } catch (error: any) {
        toast().error({
          title: "Error",
          description: error.message,
        });
        setLoading(false);
        return;
      }
    }

    if (updatedAssistant()) {
      try {
        await updateAgent(jwt()!, {
          agentId: agentId()!,
          data: { name: assistantName() },
        });
        setUpdatedAssistant(false);
      } catch (error: any) {
        toast().error({
          title: "Error",
          description: error.message,
        });
        setLoading(false);
        return;
      }
    }

    if (updatedSituation()) {
      try {
        await updateSession(jwt()!, sessionId()!, {
          situation: systemMessage().content,
        });
        setUpdatedSituation(false);
      } catch (error: any) {
        toast().error({
          title: "Error",
          description: error.message,
        });
        setLoading(false);
        return;
      }
    }

    const contentEmpty = messages().some((message) => message.content.trim() === "");

    if (contentEmpty && !isContinue()) {
      toast().error({
        title: "Content cannot be empty",
      });
      setLoading(false);
      return;
    }

    const formattedMessages = [...messages()]
      .filter((message) => message.forSubmission)
      .map((message, i, arr) => {
        const isLast = arr.length === i + 1;
        return {
          role: message.role,
          content: message.content,
          name: message.name,
          // @ts-ignore
          ...(isContinue() && isLast ? { continue: message.continue } : {}),
        };
      });

    if (formattedMessages.length === 0) {
      toast().error({
        title: "Please add or modify a message",
      });
      setLoading(false);
      return;
    }

    const payload: OpenAIRequest = {
      ...config(),
      messages: formattedMessages as OpenAIChatMessage[],
    };

    try {
      const data = await chat(jwt()!, sessionId_, {
        frequency_penalty: payload.frequency_penalty!,
        presence_penalty: payload.presence_penalty!,
        max_tokens: payload.max_tokens!,
        model: payload.model,
        temperature: payload.temperature!,
        top_p: payload.top_p!,
        messages: payload.messages,
        stream: false,
      });

      const message = { ...data!.response[0][0], forSubmission: false } as OpenAIChatMessage;

      if (isContinue()) {
        setMessages((prev) => {
          if (prev.length === 0) return prev;
          const updatedPrev = prev.map((message) => {
            return {
              ...message,
              forSubmission: false,
            };
          });
          const lastMessage = updatedPrev[updatedPrev.length - 1];
          const updatedMessage = {
            ...lastMessage,
            content: `${lastMessage?.content}${message.content}`,
            forSubmission: message.forSubmission,
          } as OpenAIChatMessage;

          return [...prev.slice(0, prev.length - 1), updatedMessage];
        });

        setIsContinue(false);
      } else {
        const newMessage = {
          ...message,
          content: message.content.trim(),
          id: uuid(),
          name: assistantName(),
        } as OpenAIChatMessage;

        setMessages((prev) => {
          message.id = uuid();
          const updatedPrev = prev.map((message) => {
            return {
              ...message,
              forSubmission: false,
            };
          });
          return [...updatedPrev, newMessage];
        });
      }
    } catch (error: any) {
      toast().error({
        title: "Error",
        description: error.message,
      });

      if (!DEV) {
        Sentry.captureException(error, error.message);
        Sentry.captureMessage(error.message, "error");
      }

      setLoading(false);
    }

    setLoading(false);
  });

  const addMessage = (content: string = "", role: "user" | "assistant" = "user", name: string = "") => {
    const userNames_ = userNames();
    setMessages((prev) => {
      const messages = [
        ...prev,
        {
          id: uuid(),
          role,
          content: content || "",
          name,
          selectedNameId: userNames_[0].id,
          forSubmission: true,
        } as OpenAIChatMessage,
      ];

      //   submit_ && submit(messages);
      return messages;
    });
  };

  const compressJson = createMemo(() => {
    const data = {
      config: config(),
      systemMessage: systemMessage(),
      assistantName: assistantName(),
      userNames: userNames(),
      messages: messages(),
    };

    const crushed = JSONCrush.crush(JSON.stringify(data));

    return encodeURIComponent(crushed);
  }, "");

  const toggleIsContinue = (id: string) => {
    setIsContinue((prev) => {
      setMessages((prev_) => {
        const index = prev_.findIndex((message) => message.id === id);
        if (index === -1) return prev_;
        const message = prev_[index];
        const conversations = [
          ...prev_.slice(0, index),
          {
            ...message,
            continue: !prev,
            forSubmission: true,
          },
          ...prev_.slice(index + 1),
        ];
        return conversations;
      });
      return !prev;
    });
  };

  const createSession = async () => {
    const session = await createNewSession(jwt()!, {
      agentId: agentId()!,
      userId: userId()!,
      situation: defaultContext.systemMessage.content,
    });

    if (!DEV) {
      Sentry.captureMessage(`Session Created: ${session.id}, JWT: ${jwt()!}`);
    }

    setSessionId(session?.id);
    setSystemMessage({
      content: defaultContext.systemMessage.content,
      role: "system",
      name: "situation",
    });
  };

  return [
    {
      systemMessage,
      assistantName,
      messages,
      config,
      loading,
      conversationId,
      conversationName,
      conversations,
      error,
      userNames,
      isContinue,
      Toaster,
      initialized,
      sessions,
    },
    {
      updateSystemMessage,
      updateAssistantName,
      addMessage,
      removeMessage,
      updateConversationName,
      deleteConversation,
      loadConversation,
      clearConversation,
      clearConversations,
      updateMessageRole,
      updateMessageName,
      updateMessageContent,
      updateConfig,
      submitAction,
      updateUsernames,
      deleteUserName,
      compressJson,
      setUsernames,
      toggleIsContinue,
      toast,
      selectSession,
      createSession,
    },
  ] as const;
};

type JulepProviderType = ReturnType<typeof useJulepProvider>;

const JulepContext = createContext<JulepProviderType>();

export default function JulepProvider(props: ParentProps) {
  const value = useJulepProvider();
  const Toaster = value[0].Toaster;
  return (
    <JulepContext.Provider value={value}>
      <Toaster />
      {props.children}
    </JulepContext.Provider>
  );
}

export const useJulep = () => useContext(JulepContext)!;
