import { Client } from "@julep/sdk";
import { Agent, InputChatMLMessage, User } from "@julep/sdk/dist/cjs/api";
import { action, cache } from "@solidjs/router";

import {
  ChatInput,
  ChatResponse,
  UserForm,
  AgentForm,
  ListAgentsResponse,
  ListUsersResponse,
  UpdateAgentRequest,
  UpdateUserRequest,
} from "~/components/workspace/sidebar/types";
import { OpenAIRequest } from "~/utils/OpenAI";
import { omitUndefined } from "~/utils/utils";

const envKey = import.meta.env.VITE_API_KEY;
const envUrl = import.meta.env.VITE_BASE_URL;

let globalClient: Client | undefined;

function createClient(apiKey: string) {
  "use server";
  const options = omitUndefined({
    apiKey,
    baseUrl: envUrl ? envUrl : undefined,
  });

  if (globalClient && globalClient instanceof Client) {
    return globalClient;
  } else {
    globalClient = new Client(options);
    return globalClient;
  }
}

async function getAgent(apiKey: string, agentId: string) {
  "use server";
  try {
    const client = createClient(apiKey);
    const agent = await client.agents.get(agentId);

    return agent;
  } catch (error) {
    throw error;
  }
}

async function getUser(apiKey: string, userId: string) {
  "use server";
  try {
    const client = createClient(apiKey);
    const user = await client.users.get(userId);

    return user;
  } catch (error) {
    throw error;
  }
}

async function getSession(apiKey: string, sessionId: string) {
  "use server";
  try {
    const client = createClient(apiKey);
    const session = await client.sessions.get(sessionId);

    return session;
  } catch (error) {
    throw error;
  }
}

async function listSessions(apiKey: string) {
  "use server";
  try {
    const client = createClient(apiKey);
    const sessions = await client.sessions.list();

    return sessions;
  } catch (error) {
    throw error;
  }
}

async function createAgent(apiKey: string, data: AgentForm) {
  "use server";
  try {
    const client = createClient(apiKey);
    const agentData = await client.agents.create({
      about: data.about,
      name: data.name,
      instructions: data.instructions,
      default_settings: data.default_settings,
      model: data.model,
    });

    return agentData;
  } catch (error) {
    throw error;
  }
}

async function createUser(apiKey: string, data: UserForm) {
  "use server";
  try {
    const client = createClient(apiKey);
    const userData = await client.users.create({
      ...data,
      about: "",
    });

    return userData;
  } catch (error) {
    throw error;
  }
}

async function updateAgent(apiKey: string, { agentId, data }: { agentId: string; data: UpdateAgentRequest }) {
  "use server";
  try {
    const client = createClient(apiKey);
    const updatedAgent = await client.agents.update(agentId, {
      ...data,
    });
  } catch (error) {
    console.error(error);
    throw error;
  }
}

async function updateUser(apiKey: string, { userId, data }: { userId: string; data: UpdateUserRequest }) {
  "use server";
  try {
    const client = createClient(apiKey);
    const updatedUser = await client.users.update(userId, {
      ...data,
    });
    return updatedUser;
  } catch (error) {
    console.error(error);
    throw error;
  }
}

async function createSession(
  apiKey: string,

  {
    userId,
    agentId,
    situation,
  }: {
    userId: string;
    agentId: string;
    situation?: string;
  }
) {
  "use server";

  try {
    const client = createClient(apiKey);
    const session = await client.sessions.create({
      userId,
      agentId,
      situation,
    });

    return session;
  } catch (error) {
    console.error("createSession error", error);
    throw error;
  }
}

async function updateSession(apiKey: string, sessionId: string, data: { situation: string; metadata?: any }) {
  "use server";
  try {
    const client = createClient(apiKey);
    const session = await client.sessions.update(sessionId, { ...data });

    return session;
  } catch (error) {
    console.error("updateSession error", error);
    throw error;
  }
}

async function listAgents(apiKey: string): Promise<Agent[]> {
  "use server";
  try {
    const client = createClient(apiKey);
    const agents = await client.agents.list();

    return agents;
  } catch (error) {
    throw error;
  }
}

async function listUsers(apiKey: string): Promise<User[]> {
  "use server";
  try {
    const client = createClient(apiKey);
    const users = await client.users.list();

    return users;
  } catch (error) {
    throw error;
  }
}

async function getSessionHistory(apiKey: string, sessionId: string) {
  "use server";
  try {
    const client = createClient(apiKey);
    const history = await client.sessions.history(sessionId);

    return history;
  } catch (error) {
    throw error;
  }
}

async function chat(
  apiKey: string,
  sessionId: string,
  data: {
    messages: InputChatMLMessage[];
    model: string;
    temperature: number;
    max_tokens: number;
    top_p: number;
    frequency_penalty: number;
    presence_penalty: number;
    stream: boolean;
  }
): Promise<ChatResponse> {
  "use server";
  try {
    const client = createClient(apiKey);
    const reply = await client.sessions.chat(sessionId, {
      ...data,
    });
    // @ts-ignore
    return reply;
  } catch (error) {
    console.error("chat error", error);
    throw error;
  }
}

export const getAgentCache = cache(getAgent, "getAgent");
export const getAgentAction = action(getAgent, "getAgent");
export const getUserCache = cache(getUser, "getUser");
export const getUserAction = action(getUser, "getUser");
export const getSessionAction = action(getSession, "getSession");
export const createUserAction = action(createUser, "createUser");
export const createAgentAction = action(createAgent, "createAgent");
export const updateAgentAction = action(updateAgent, "updateAgent");
export const updateUserAction = action(updateUser, "updateUser");
export const createSessionAction = action(createSession, "createSession");
export const updateSessionAction = action(updateSession, "updateSession");
export const listSessionsCache = cache(listSessions, "getSessionsList");
export const listSessionsAction = action(listSessions, "getSessionsList");
export const getSessionHistoryCache = cache(getSessionHistory, "getSessionHistory");
export const chatAction = action(chat, "chat");
export const getSessionHistoryAction = action(getSessionHistory, "getSessionHistory");
